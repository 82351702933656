import { TeamMemberRole } from "./ITeamFirebase";
import { IGenericModelFields } from "./IGenericFields";

export interface ITeamInvite extends IGenericModelFields {
    createdAt: number;
    modifiedAt: number;
    invitedBy: string;
    inviteeEmail: string;
    inviteeUid: string;
    role: TeamMemberRole;
    teamId: string;
    orgId: string;
    status: string;
}
