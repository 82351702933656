import { WorkspaceUserRole } from "./IWorkspace";
import { IGenericModelFields } from "../IGenericFields";

export interface IWorkspaceUserInvite extends IGenericModelFields {
    /** Workspace ID */
    readonly workspaceId: string;
    /** Role of the user who is being invited */
    role: WorkspaceUserRole;
    /** UID of the user who invited */
    from: string;
    /** Email of the user who is being invited */
    email: string;
    /** Timestamp when the invite expires, null if the invite doesn't expire */
    validTill: number | null;
    /** Timestamp when the invite was accepted, null if the invite is not accepted */
    acceptedAt: number | null;
    /** UID of the user who accepted the invite, null if the invite is not accepted */
    acceptedBy: string | null;
    /** Group IDs for the invited user to be added to */
    groupIds: string[];
}
