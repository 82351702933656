import type Stripe from "stripe";

interface IExtendedStripePrice extends Stripe.Price {
    product: Stripe.Product;
}

interface IStripeSubscriptionExtendedItem extends Stripe.SubscriptionItem {
    price: IExtendedStripePrice;
}

interface IStripeSubscriptionExtendedSchedule extends Stripe.SubscriptionSchedule {
    phases: IStripeSubscriptionExtendedSchedulePhase[];
}

interface IStripeSubscriptionExtendedSchedulePhaseItem extends Stripe.SubscriptionSchedule.Phase.Item {
    price: Stripe.Price;
}

interface IStripeSubscriptionExtendedSchedulePhase extends Stripe.SubscriptionSchedule.Phase {
    items: IStripeSubscriptionExtendedSchedulePhaseItem[];
}

export interface IStripeSubscriptionExtended extends Stripe.Subscription {
    schedule: IStripeSubscriptionExtendedSchedule;
    items: Stripe.ApiList<IStripeSubscriptionExtendedItem>;
    default_payment_method: Stripe.PaymentMethod;
    current_billing_interval: Stripe.Price.Recurring.Interval;
    scheduled_billing_interval: Stripe.Price.Recurring.Interval;
    latest_invoice: Stripe.Invoice;
}
