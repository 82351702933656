import { IGenericModelFields } from "./IGenericFields";

export interface IAuthUser {
    id: string;
    uid: string;
    email: string;
    emailVerified: boolean;
    displayName: string;
    photoURL: string;
}

export interface IUser extends IGenericModelFields {
    createdAt: number;
    modifiedAt: number;
    lastSignedInAt: number;
    lastActive: number;

    // general info
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    provider: string;
    messagesSeen: Record<string, boolean>;
    presentationSort: string;
    presentationNotificationsSettings: any;
    pptFontsMap: any;
    cancelPlanSurvey: any;
    librarySettings: any;
    searchKeys: any;
    userInfo: any;
    teamMigration: any;
    trialEndsTimestamp: number;
    workspaceRoleChange: any;
    workspaceDeleted: boolean;
    organizationId: string;
    organizatinRole: string;
    firstPresentationId: string;
    trackUsageData: boolean;
    provisioning?: {
        sourceId: string;
        sourceMetadata: {
            sid: string,
            [key: string]: any
        };
    }

    // random flags
    active: boolean;
    admin: boolean;
    isApiEnabled: boolean;
    didConfirmPlan: boolean;
    forcedDowngrade: boolean;
    forcedWorkspaceDowngrade: boolean;
    forcedWorkspaceRemoval: string;
    hasTakenTour: boolean;
    hasTakenTeamTrial: boolean | number;
    hasUsedPPTAddin: boolean;
    ignoreSpellcheck: boolean;
    ignoreFontWarning: boolean;
    notifyByEmail: boolean;
    notifyWithPopup: boolean;
    _migrated: boolean;
    // viewedXX ones are not used anymore, so delete after confirming
    viewedCreateTeam: boolean;
    viewedManageLibrarySlides: boolean;
    viewedManageTeam: boolean;
    _changeId: string;
    edu: {
        eduStartDate: number;
    }

    // gdrive integration
    isGDriveEnabled: boolean;
    gDriveChannelId?: string;
    gDriveChannelExpiration?: number;

    // slack integration
    isSlackEnabled: boolean;
    slackBotUserId: string;
    slackChannelId: string;
    slackTeamId: string;
    slackTeamName: string;

    // stripe stuff
    customerId: string;
    stripeSubscriptionId: string;
    subscriptionStatus: string;

    // resource references
    orgs: { [orgId: string]: boolean };
    teams: { [teamId: string]: boolean };
    teamFolders: { [teamFolderId: string]: boolean } | { _changeId?: string, id?: string, modifiedAt?: number };
    userFolders: { [userFolderId: string]: boolean } | { _changeId?: string, id?: string, modifiedAt?: number };
    presentations: { [presentationId: string]: boolean } | { _changeId?: string, id?: string, modifiedAt?: number };
    user_themes: { [themeId: string]: boolean };
    sharedThemes: { [themeId: string]: boolean };
}

export interface IUserFolderFirebase {
    id: string;
    _changeId: string;
    userId: string;
    orgId?: string;
    name: string;
    presentations?: { // {  "0": "presentationId" }
        [key: string]: string;
    };
    subFolders?: {
        [key: string]: {
            id: string;
            name: string;
            presentations?: { // {  "0": "presentationId" }
                [key: string]: string;
            };
        };
    };
    createdAt: number;
    modifiedAt: number;
}
