import { WorkspaceUserRole } from "./IWorkspace";
import { IGenericModelFields } from "../IGenericFields";

export interface IWorkspaceUser extends IGenericModelFields {
    readonly workspaceId: string;
    role: WorkspaceUserRole;
    readonly uid: string;
    groupIds: string[];
}

export interface IWorkspaceUserExtended extends IWorkspaceUser {
    email: string;
    displayName: string;
    photoURL: string;
    lastActive: number;
    disabled: boolean;
    _migrated: boolean;
}
