import { IWorkspaceMetadata } from "./workspaces/IWorkspace";
import { IUser } from "./IUser";

export interface IChangeset<T> {
    original: Partial<T>;
    update: Partial<T>;
    hasUpdates: boolean;
}

export interface IStorageModelOptions {
    silent?: boolean;
    removeMissingKeys?: boolean;
    replaceKeys?: boolean;
    unsetSilent?: boolean;
    remoteChange?: boolean;
}

export type IStorageModel<T, AdditionalProps = { [methodOrProp: string]: ((...args: any[]) => any) | any; }> = {
    id: string;
    readonly: boolean;
    root: string;
    userId: string;
    autoSync: boolean;
    attributes: T;
    loaded: boolean;
    isLoaded: boolean;
    hasTakenTrial: boolean;
    analyticsPersonalPlan: string;
    get: <K extends keyof T>(key: K) => T[K];
    has: (key: keyof T) => boolean;
    update: (data: Partial<T>, options?: IStorageModelOptions) => IChangeset<T>;
    load: () => Promise<void>;
    reload: () => Promise<void>;
    replace: (data: T, options?: IStorageModelOptions) => void;
    updatePromise: Promise<void>;
    disconnect: () => void;
    destroy: (options?: IStorageModelOptions) => Promise<void>;
    toObject: () => Promise<T>;
    on: (event: string, callback: (model: IStorageModel<T>) => void) => void;
    off: (event?: string, callback?: (model: IStorageModel<T>) => void) => void;
    changed: Partial<T>;
} & AdditionalProps;

export type IUserStorageModel = IStorageModel<IUser, {
    workspacesMetadata: IWorkspaceMetadata[];
    loadWorkspacesMetadata: () => Promise<void>;
    getAuthUser: () => any; // Avoid importing firebase types here
    getAvatarUrl: () => string;
    getPresentationIds: () => string[];
}>
