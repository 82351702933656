export interface IOrganizationFirebase {
    id: string;
    _changeId: string;
    adminUids: {
        [userId: string]: boolean;
    };
    defaultTeamId: string;
    teams?: {
        [teamId: string]: boolean;
    };
    isDomainVerified: boolean;
    domain: string;
    domainVerificationKey: string;
    domainVerificationError: string;
    domainVerifiedAt: number;
    skipDomainVerification: boolean;
    sso?: {
        allowIdpInitiated: boolean;
        samlConfig: {
            audience: string;
            callbackUrl: string;
            cert: string;
            certs: string[];
            entryPoint: string;
            idpIssuer: string;
            issuer: string;
            signatureAlgorithm: string;
            samlSSOEndpoint: string;
        };
        strict: boolean;
    };
    userProvisioning?: {
        allowJit: boolean;
        allowScim: boolean;
        scimToken: string;
    };
    salesforce?: {
        clientId: string;
        domainName: string;
        prn: string;
        tokens: {
            access_token: string;
            instance_url: string;
            scope: string;
            token_type: string;
            id: string;
        };
    };
    totalSeatCount: number;
    customerId: string;
    stripeSubscriptionId: string;
    subscriptionStatus: string;
    createdAt: number;
    modifiedAt: number;
}
