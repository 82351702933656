import { getCanvasBundle } from "js/canvas";
import { appVersion } from "js/config";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { Presentation } from "js/core/models/presentation";
import { SharedThemes } from "js/core/models/sharedTheme";
import { Slide } from "js/core/models/slide";
import { BuiltInThemes, Theme, UserThemes } from "js/core/models/theme";
import Adapter from "js/core/storage/adapter";
import FirebaseAdapter from "js/core/storage/firebaseAdapter";
import { ThemeManager } from "js/core/themeManager";
import { timeoutRace } from "js/core/utilities/promiseHelper";
import { auth } from "js/firebase/auth";
import { app } from "js/namespaces";
import { $, _ } from "js/vendor";

const themeManager = new ThemeManager();

const noop = () => { };
const RENDER_TIMEOUT = 30 * 1000;

const logger = getLogger(LogGroup.RENDERER);

Adapter.setDefaultClass(FirebaseAdapter, { autoSync: false });

class ThumbnailRenderer {
    static get CANVAS_WIDTH() {
        return 1280;
    }

    static get CANVAS_HEIGHT() {
        return 720;
    }

    constructor() {
        this.canvas = null;
    }

    async initialize() {
        logger.info("initialize");

        const muiFonts = document.fonts.keys().filter(font => font.family.includes("Material"));
        await Promise.all(muiFonts.map(font => font.load()));

        ds.prepare({ SharedThemes, BuiltInThemes, UserThemes });

        app.user = app.user || { get: noop, getEmail: noop };

        ds.dummySetup();
    }

    async start({
        slideId,
        slideModel,
        slideIndex,
        theme,
        authToken,
        hideBaiBranding
    }) {
        logger.info("start");
        this.startTime = new Date().valueOf();

        this.hideBaiBranding = hideBaiBranding;

        // login with token
        const { user } = await this.loginWithToken(authToken);
        app.user = {
            id: user.uid,
            get: noop,
            getEmail: noop
        };
        // load presentation
        const presentation = await this.loadPresentation(theme, user.uid);
        // load slide
        const slide = await this.loadSlide(slideId, slideModel, presentation);
        // load theme
        await themeManager.loadTheme(presentation);
        // render canvas
        await timeoutRace(
            RENDER_TIMEOUT,
            this.render(slide, slideIndex)
        );
        return { stagesCount: this.canvas.playbackStages.length || 1 };
    }

    loginWithToken(authToken) {
        logger.info("loginWithToken");
        return auth().signInWithCustomToken(authToken);
    }

    async loadSlide(slideId = null, slideModel = null, presentation) {
        logger.info("loadSlide");

        if (slideModel) {
            return new Slide(slideModel, { disconnected: true, presentation });
        }

        if (slideId) {
            const slide = new Slide(
                {
                    id: slideId
                },
                {
                    autoSync: false,
                    presentation,
                }
            );
            await slide.load();
            return slide;
        }

        throw new Error("slideId or slideModel required");
    }

    async loadPresentation(theme, userUid) {
        logger.info("loadPresentation");

        // Creating an ephemerial presentation
        const presentation = new Presentation(
            {
                id: _.uniqueId(),
                userId: userUid,
                themeId: theme.id ?? null
            },
            { disconnected: true, autoLoad: false }
        );
        presentation.permissions.write = true;
        // Assigning the theme
        await themeManager.saveThemeToPresentation(new Theme(theme, { disconnected: true, autoLoad: false }), presentation);
        // Setting the presentation as current presentation
        ds.selection.presentation = presentation;

        return presentation;
    }

    async render(slide, slideIndex) {
        logger.info("render");

        // Load slide canvas bundle
        const { SlideCanvas } = await getCanvasBundle(slide.get("version") ?? appVersion);

        this.canvas = new SlideCanvas({
            dataModel: slide,
            canvasWidth: ThumbnailRenderer.CANVAS_WIDTH,
            canvasHeight: ThumbnailRenderer.CANVAS_HEIGHT,
            editable: false,
            isPlayback: true,
            showTextPlaceholders: slide.get("isTeamSlideTemplate"),
            noThumbnail: true,
            slideIndex,
            errorStateOptions: {
                showErrorMessage: false
            },
            generateImages: false,
            showBranding: !this.hideBaiBranding
        });
        app.currentCanvas = this.canvas;

        const scale = Math.min(
            window.innerWidth / ThumbnailRenderer.CANVAS_WIDTH,
            window.innerHeight / ThumbnailRenderer.CANVAS_HEIGHT
        );
        this.canvas.canvasScale = scale;

        // render and layout the canvas
        $("body").append(this.canvas.render().$el);
        this.canvas.$el.css({
            position: "absolute",
            top: 0,
            left: 0,
            transform: `scale(${scale})`
        });

        await this.canvas.renderSlide()
            .catch(err => {
                logger.error(err, "Error rendering slide, will show error state");
            });
    }

    waitForImagesToLoad() {
        return this.canvas.waitForImages();
    }

    getSlideNotes() {
        const slideNotes = [];

        if (this.canvas.hasSlideNotes()) {
            slideNotes.push(this.canvas.getNormalizedSlideNotes());
        }

        this.canvas.getElements().forEach(element => {
            if (element.publicVideoUrl) {
                slideNotes.push(`Video URL: ${element.publicVideoUrl}`);
            }
        });

        return slideNotes.join("\n");
    }

    getSlideModifiedAtValue() {
        return this.canvas.dataModel.get("modifiedAt") ?? Date.now();
    }

    async completeTask() {
        logger.info("completeTask");
        this.canvas.remove();
        await auth().signOut();
    }
}

window.thumbnailRenderer = new ThumbnailRenderer();
